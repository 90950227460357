import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"
import TwoAndOne from "../../components/twoAndOne"
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      frances: file(relativePath: { eq: "mitarbeiter/frances.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Rehabilitation & Nachsorge" />
      <Header siteTitle="Beratung" subTitle="Rehabilitation & Nachsorge" />
      <TwoAndOne>
        <div className="content">
          <h3>Ambulante Rehabilitation</h3>
          <p>
            Während der Motivationsphase stehen oft die aktuellen, durch das
            Glücksspielen entstandenen Probleme im Vordergrund. Wichtige Themen
            in dieser Phase sind vor allem „Umgang mit Suchtdruck“, „Sicherung
            der Finanzen“, „Aufbau einer Abstinenz von Glücksspielen“ sowie
            „Stabilisierung von belasteten (Paar-) Beziehungen“. Für viele
            Betroffene ist diese erste Phase bereits mit einer deutlichen
            Verbesserung der belasteten Lebenssituation verbunden.
          </p>
          <p>
            Um eine stabile Abstinenz von Glücksspielen aufbauen zu können,
            bedarf es in vielen Fällen jedoch einer intensiveren
            Auseinandersetzung mit der eigenen Erkrankung sowie einer
            Bearbeitung der entstandenen Folgeprobleme.
          </p>
          <p>
            Im Rahmen der ambulanten Rehabilitation bieten wir Betroffenen die
            Möglichkeit, aktiv die Ursachen und Folgen ihrer Glücksspielsucht zu
            bearbeiten. In therapeutischen Einzel- und Gruppensitzungen lernen
            Betroffene ihre Erkrankung sowie deren Ursachen und Auslöser besser
            kennen und entwickeln hilfreiche Verhaltensmuster für ein
            suchtfreies Leben. Die Therapieplanung erfolgt zusammen mit den
            Betroffenen und unserem therapeutischen Team unter ärztlicher
            Leitung. Während der Rehabilitation steht den Rehabilitandinnen und
            Rehabilitanden eine Bezugstherapeutin oder ein Bezugstherapeut zur
            Seite.
          </p>
          <p>
            Da die Gruppensitzungen in den Abendstunden stattfinden und Termine
            für Einzelgespräche individuell vereinbart werden, können die
            Teilnehmenden während der ambulanten Rehabilitation ihrer
            beruflichen Tätigkeit nachkommen und in ihrem privaten Lebensumfeld
            verbleiben.
          </p>
          <h3>Ambulante Weiterbehandlung</h3>
          <p>
            Für Rehabilitandinnen und Rehabilitanden, die während einer
            stationären Rehabilitation nicht alle wichtigen Rehabilitationsziele
            erreichen konnten, bietet sich die Möglichkeit, die therapeutische
            Arbeit im ambulanten Rahmen weiter zu führen. Eine ambulante
            Weiterbehandlung nach stationärer Rehabilitation muss durch die
            stationäre Reha-Einrichtung vor Ablauf der Maßnahme beim zuständigen
            Rentenversicherungsträger beantragt werden. Durch die therapeutische
            Arbeit können die Ziele weiter bearbeitet und direkt im Alltagsleben
            erprobt werden. Somit besteht die Möglichkeit den Therapieerfolg
            weiter auszubauen, ihn zu festigen und sich somit vor Rückfällen zu
            schützen.
          </p>
          <h3>Nachsorge</h3>

          <p>
            Ähnlich wie die ambulante Weiterbehandlung nach Beendigung der
            stationären Rehabilitation, hat auch die Nachsorge das Ziel Ihren
            Rehabilitationserfolg zu festigen. Die Maßnahme wird bereits während
            der stationären Rehabilitation durch die Klinik beantragt. Im
            Unterschied zur ambulanten Weiterbehandlung umfasst diese Maßnahme
            weniger Termine und findet überwiegend im Gruppensetting statt.
          </p>
        </div>
        <div className="content">
          <h4 className="title">Ansprechpartnerin</h4>
          <div className="columns">
            <div className="column is-one-third">
              <Img fluid={data.frances.childImageSharp.fluid} />
            </div>
            <div className="column ">
              <h5 className="title">Frances Trümper</h5>
              <h6 className="subtitle">
                Dipl.-Sozialarbeiterin, Sozial- und Suchttherapeutin (VT),
                Leitung der Fachberatungsstelle
              </h6>
              <p className="is-size-7">
                <a href="mailto:frances.truemper@ak-spielsucht.de ">
                  <span class="icon">
                    <i class="fas fa-envelope"></i>
                  </span>
                  frances.truemper@ak-spielsucht.de
                </a>
                <br />
                <span class="icon">
                  <i class="fas fa-phone"></i>
                </span>
                02303 89669
              </p>
            </div>
          </div>
          <h4 className="title">Weitere Beratungsangebote</h4>
          <ul>
            <li>
              <Link to="/beratung/motivationsgruppe">Motivationsgruppe</Link>
            </li>
          </ul>
        </div>
      </TwoAndOne>
    </Layout>
  )
}

export default IndexPage
